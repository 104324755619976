.welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 20px;
}

.profile-info-box, .info-box {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  color: white;
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  margin: 10px auto;
}

.left-aligned {
  text-align: left;
}

.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button-container {
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-play, .btn-download, .btn-update, .btn-secondary {
  border: none;
  border-radius: 5px;
  padding: 12px 20px; /* Ajustado para un mejor toque visual */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
  margin: 5px;
  font-size: 16px; /* Asegura visibilidad */
  font-weight: bold; /* Hace el texto más legible */
  text-align: center; /* Asegura que el texto esté centrado */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Añade sombra para un efecto 3D */
}

.btn-download.parche {
  background-color: #E65100; /* Anaranjado oscuro específico para el botón de parche */
}

.btn-download.parche:hover {
  background-color: #BF360C; /* Anaranjado más oscuro al hacer hover */
}

.btn-play {
  background-color: #4CAF50;
}

.btn-play:hover {
  background-color: #367C39;
}

.btn-download {
  background-color: #00BCD4;
}

.btn-download:hover {
  background-color: #008BA3;
}

.btn-update {
  background-color: #FF9800;
  display: none;
}

.btn-update:hover {
  background-color: #E65100;
}

.btn-update.visible {
  display: block;
}

.btn-secondary {
  background-color: #6b7280;
}

.btn-secondary:hover {
  background-color: #4B5563;
}

.btn-play:disabled, .btn-download:disabled, .btn-secondary:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}

.success-message {
  color: green;
  font-size: 16px;
  margin-top: 10px;
}

.select-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  display: flex;
}

.select-container select {
  width: 100%;
  padding: 12px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border: 1px solid #555;
  border-radius: 5px;
  appearance: none;
}

.select-container::after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #FF9800;
  font-size: 24px;
  pointer-events: none;
}

.subtitle {
  color: orange;
  text-shadow: 1px 1px 1px black;
  margin-bottom: 10px;
}

.server-select-label {
  color: orange;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
}

.info-box ul {
  margin: 10px 0;
}

.subtitle {
  color: orange;
  font-style: italic; /* Agrega efecto itálico */
  text-shadow: 1px 1px 1px black;
  margin-bottom: 10px;
}

.server-select-title {
  color: orange;
  font-style: italic; /* Agrega efecto itálico */
  text-shadow: 1px 1px 1px black;
  margin-top: 20px; /* Espacio antes del subtítulo */
}



@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Uncial+Antiqua&display=swap');

.medieval-title {
  font-family: 'Cinzel', serif;
  color: #FFD700;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
  font-size: 32px;
  margin-bottom: 10px;
  text-align: center;
}

.medieval-subtitle {
  font-family: 'Uncial Antiqua', cursive;
  color: #FFA500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.server-select-title {
  font-family: 'Uncial Antiqua', cursive;
  color: #FFD700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.game-details {
    list-style-type: none;
    padding-left: 0;
    font-family: 'MedievalSharp', serif;
    font-size: 14px;
    color: #fff;
    text-align: left;
  }