.info-box {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    margin: 20px auto; /* Centrado y margen */
    color: white;
    max-width: 500px; /* Aumenta el ancho máximo */
    width: 80%; /* O puedes usar un porcentaje del ancho de la ventana */
}

  
  .login-input {
    display: block;
    margin: 10px auto;
    padding: 10px;
    width: 100%; /* Ajuste de ancho */
    box-sizing: border-box; /* Asegura que el padding no aumente el ancho */
  }
  
.login-input {
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 15px;
    width: calc(100% - 40px);
  }
  
  .start-button {
    background-color: #61dafb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .start-button:hover {
    background-color: #4fa3d5;
  }
  
  .input-field {
    background-color: rgba(255, 255, 255, 0.1); /* Fondo blanco con opacidad */
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
    color: #ffffff; /* Texto blanco */
    width: 100%;
    transition: background-color 0.3s; /* Transición suave al pasar el cursor */
}

.input-field:hover {
    background-color: rgba(255, 255, 255, 0.3); /* Fondo blanco con opacidad al pasar el cursor */
}

.btn-primary, .btn-secondary {
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    color: #ffffff; /* Texto blanco */
    cursor: pointer;
    transition: background-color 0.3s; /* Transición suave al pasar el cursor */
    display: block;
    width: 100%; /* Ocupar el ancho completo */
    margin: 10px 0; /* Margen para separarlos */
}

.btn-primary {
    background-color: #588ad1; /* Color primario */
}

.btn-primary:hover, .btn-secondary:hover {
    background-color: #6b7280; /* Color al pasar el cursor */
}

.btn-secondary {
    background-color: #467733; /* Color secundario */
}

  .error-message {
    color: red; /* Cambia el color del texto a rojo para indicar un error */
    margin: 10px 0; /* Mantiene el margen para separar el mensaje de otros elementos */
    text-align: center; /* Asegura que el texto esté centrado */
  }

/* Estilos para los placeholders */
.input-field::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff; /* Color blanco */
  opacity: 0.5; /* Opacidad al 50% */
}

.input-field:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ffffff;
  opacity: 0.5;
}

.input-field::-ms-input-placeholder { /* Microsoft Edge */
  color: #ffffff;
  opacity: 0.5;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.input-field {
  flex-grow: 1;
  padding: 10px 40px 10px 10px; /* Espacio adicional a la derecha para el ícono */
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 2px solid transparent; /* Borde transparente por defecto */
  border-radius: 5px;
  transition: border-color 0.3s;
}

.input-field:focus {
  outline: none; /* Elimina cualquier contorno predeterminado */
  border-color: transparent; /* Mantiene el borde transparente incluso en foco */
}

.input-field.valid {
  border-color: green; /* Borde verde si los datos son válidos */
}

.input-field.invalid {
  border-color: red; /* Borde rojo si los datos son inválidos */
}

.validation-icon {
  position: absolute;
  right: 10px; /* Posición a la derecha dentro del input */
  top: 50%; /* Centra el ícono verticalmente */
  transform: translateY(-50%); /* Ajuste para centrar verticalmente el ícono */
  font-size: 18px; /* Tamaño del ícono aumentado */
  color: transparent; /* Inicialmente transparente hasta que se ingrese texto */
  transition: color 0.3s;
}

.validation-icon.valid {
  color: green;
}

.validation-icon.invalid {
  color: red;
}
