.welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 20px;
}

.info-box {
  background-color: rgba(0, 0, 0, 0.6); /* Un poco más oscura */
  padding: 20px;
  border-radius: 10px;
  color: #E1C699; /* Color dorado suave */
  width: 80%;
  text-align: center;
  margin-bottom: 20px; /* Separación con la tabla */
}

.data-grid-container {
  width: 80%; /* Ajuste el ancho de la tabla */
  height: 600px; /* Altura fija de la tabla */
  border: 1px solid #E1C699; /* Borde dorado suave alrededor de la tabla */
  border-radius: 10px;
  overflow: hidden;
}

/* Personalización del scrollbar */
.MuiDataGrid-root ::-webkit-scrollbar-thumb {
  background-color: #E1C699 !important; /* Scroll dorado suave */
  border-radius: 5px !important;
}

.MuiDataGrid-root ::-webkit-scrollbar-track {
  background-color: #222 !important; /* Fondo oscuro para el scroll */
}
