.navbar-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Para asegurarte de que el menú esté por encima del contenido */
}

/* Añade margen superior al contenido para evitar que se solape con el menú fijo */
.content-padding {
  margin-top: 80px; /* Ajusta este valor dependiendo del tamaño del menú */
}


body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Cinzel', serif; /* Fuente medieval */
  color: white;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Fondo oscuro con más opacidad */
  z-index: -1;
}

.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%), url('img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
  background-attachment: fixed; /* Fija el fondo al scroll */
}

.App .content {
  padding-bottom: 150px; /* Espacio adicional para evitar que el contenido se superponga a la iluminación */
}


.footer-glow {
  height: 150px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0) 70%);
  margin-top: 50px;
}

.welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  /*padding-bottom: 150px;  Asegura espacio suficiente para todo el contenido */
}

.info-box {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 15px;
  margin: 20px;
  color: white;
  max-width: 600px;
  width: 80%;
}


.server-list-container {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column; /* Asegura que los botones estén en columna */
  align-items: center; /* Centra los botones horizontalmente */
}

.server-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%; /* Asegúrate de que ocupe todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los elementos en la lista */
}

.server-list li {
  margin: 10px 0;
  width: 100%;
}

.server-list button {
  width: 100%;
  padding: 15px;
  background-color: #1b1b1a; /* Marrón oscuro con un toque dorado */
  color: #ffc107; /* Dorado brillante para el texto */
  border: 2px solid #b08504; /* Borde dorado oscuro */
  border-radius: 10px;
  text-align: center; /* Centra el texto dentro del botón */
  cursor: pointer;
  font-family: 'Cinzel', serif;
  font-size: 18px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.server-list button:hover,
.server-list button.active {
  background-color: #24231f; /* Dorado oscuro más claro para el hover */
  box-shadow: 0px 0px 12px #ffeb3b; /* Sombra dorada brillante */
}


.shared-download-text {
  color: #FFD700;
  font-size: 16px;
  margin-bottom: 10px;
}

.downloads-subtitle {
  font-size: 16px;
  color: #FFA500;
  margin-bottom: 10px;
}

.download-link {
  display: block;
  color: #61dafb;
  margin-bottom: 5px;
}

.download-link:hover {
  color: #21a1f1;
}

.game-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.medieval-title {
  font-family: 'Cinzel', serif;
  color: #FFD700;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
  font-size: 48px;
  margin-bottom: 20px;
}

.medieval-subtitle {
  font-family: 'Uncial Antiqua', cursive;
  color: #FFA500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 20px;
  margin-bottom: 30px;
}

.game-title {
  font-size: 2.5em; /* Tamaño de fuente más grande */
  color: #FFD700; /* Color amarillo dorado */
  text-align: center;
  margin-bottom: 10px; /* Añade un margen inferior para separarlo del carrusel */
}

.game-subtitle {
  font-size: 1.2rem;
  color: #777;
  margin-top: 10px;
}

.game-details {
  list-style-type: none;
  padding-left: 0;
  font-family: 'Cardo', serif; /* Cambiamos la fuente a una medieval no mayúscula */
  font-variant: normal; /* Aseguramos que no use mayúsculas pequeñas */
  font-size: 18px; /* Ajusta el tamaño según lo que prefieras */
  text-transform: none; /* Aseguramos que no se transforme a mayúsculas */
  font-size: 14px;
  color: white;
  text-align: center;
}

.game-details li {
  margin-bottom: 10px;
  font-size: 16px;
}

.game-details a {
  display: inline-block; /* Comportamiento de bloque en línea para parecer botón */
  background-color: #1b1b1a; /* Color oscuro de fondo */
  color: #ffc107; /* Color dorado brillante para el texto */
  text-decoration: none; /* Eliminamos el subrayado */
  padding: 10px 20px; /* Ajustamos el padding para hacerlo más pequeño */
  border: 2px solid #b08504; /* Borde dorado */
  border-radius: 8px; /* Bordes redondeados */
  font-family: 'Cinzel', serif; /* Fuente medieval legible */
  font-size: 14px; /* Tamaño de texto más pequeño */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Transición suave */
  margin-top: 10px; /* Separación entre botones */
}


.game-details a:hover {
  text-decoration: underline;
}

.close-button {
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  z-index: 1001;
  transition: transform 0.6s ease, background-color 0.3s ease;
}

.close-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  transform: rotate(360deg);
}

/* Centrar el botón de 'Descargar' */
.download-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-download {
  background-color: #00bfff;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  font-family: 'Cinzel', serif;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-download:hover {
  background-color: #1e90ff;
  transform: scale(1.05);
}

.server-info-box {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 15px;
  margin: 0px auto; /* Centra el bloque */
  color: white;
  max-width: 600px; /* Asegura el mismo tamaño que el bloque principal */
  width: 80%; /* Ajusta el ancho al 80% de la pantalla */
  position: relative; /* Necesario para posicionar el botón "Volver" dentro del bloque */
  display: flex;
  flex-direction: column; /* Asegura que el contenido se alinee en columna */
}

.btn-back {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #00bfff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-family: 'Cinzel', serif;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-back:hover {
  background-color: #1e90ff;
  transform: scale(1.05);
}


.server-list-container {
  margin-bottom: 0; /* Eliminar espacio entre la lista de servidores y las redes sociales */
}
.social-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 10px; /* Espacio adicional para que estén al final */
  padding-bottom: 50px; /* Espacio al final para mayor separación */
}

.social-icon {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.3); /* Aumenta el tamaño al 130% */
}


.button-container {
  display: flex;
  justify-content: space-between; /* Asegura que los botones se mantengan separados */
  align-items: center; /* Alinea los botones verticalmente en el centro */
  margin-top: 20px; /* Agrega espacio arriba para evitar que se superpongan */
}

.btn-download, .btn-back {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
}

.btn-download {
  background-color: #00bfff;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-back {
  background-color: #ffd700; /* Color amarillo dorado */
  color: black;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-download:hover, .btn-back:hover {
  transform: scale(1.05); /* Aumenta ligeramente el tamaño al pasar el mouse */
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}


.game-info {
  text-align: center;
}


.badge, .badge-reset, .badge-online {
  display: inline-block;
  padding: 3px 8px; /* Reducir el padding para que sean más pequeños */
  margin: 0 3px; /* Reducir el margen entre los badges */
  border-radius: 3px;
  margin-bottom: 10px;
}

.badge {
  display: inline-block;
  background-color: #444;
  color: #f0c040;
}

.badge-reset {
  display: inline-block;
  background-color: #333;
  color: #ff6363;

}

.badge-online {
  display: inline-block;
  background-color: #333;
  color: #bff5a0;
  margin-bottom: 10px;
}
